.services {
  min-height: 90vh;
  margin-top: 20px;
}

.serviceImage img {
  width: 370px;
  height: 300px;
  border-radius: 10px;
}
