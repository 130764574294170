.home {
  min-height: 90vh;
  margin-top: 20px;
}

.home-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.home-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.box h4 {
  font-size: 27px;
  margin-bottom: 15px;
}

.home-image-details {
  padding: 22px;
}

.home-paragraph {
  text-align: justify !important;
  line-height: 32px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.sg-row {
  margin-bottom: 30px;
}

.box p {
  color: #fff;
}

.sg-row span {
  font-size: 35px;
}

.home-third {
  margin-bottom: 18px;
}

#box90 {
  background-color: #08071c;
  padding: 22px;
}
