.faq {
  min-height: 70vh;
  margin-top: 20px;
}

#react-faq-rowtitle-1 {
  font-weight: 600;
  font-size: 20px;
}

.styles_row-content-text__2sgAB {
  opacity: 0.8;
  font-weight: 400;
  font-size: 16px;
}

.faq-section {
  margin-top: 20px;
}
