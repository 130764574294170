.subregion {
  min-height: 90vh;
  margin-top: 20px;
}

.subregion-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr) !important;
}

.s-region {
  margin-top: 5%;
}

.subregion-grid {
  margin-bottom: 80px;
}

.subregion-grid p {
  font-size: 20px;
  text-align: justify;
}

.subregion-grid-img {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin-top: 50px;
  margin-bottom: 40px;
}

.region-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  margin-top: 50px;
}

.subregion-btn {
  padding: 8px 24px;
  background-color: #674bae;
  border: 1px solid #674bae;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  width: 20%;
  margin-left: 38%;
}
